import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
import FullWidthImage from "../components/FullWidthImage";

import Content, { HTMLContent } from "../components/Content";

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ResearchRoll from "../components/ResearchRoll";

// eslint-disable-next-line
export const ProductPageTemplate = ({
  image,
  title,
  lang,
  heading,
  description,
  intro,
  contentComponent,
  content,
}) => {
  const PageContent = contentComponent || Content;
  const heroImage = getImage(image) || image;

  return (
    <div className="content">
      <Navbar lang={lang} />
      <FullWidthImage img={heroImage} title={title} />
      <section className="section section--gradient">
        <Helmet
          title={`${title} | ${
            lang === "ja" ? "飯山研究室" : "Iiyama Laboratory"
          }`}
        />
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-7 is-offset-1">
                <PageContent className="content" content={content} />
              </div>
            </div>
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <ResearchRoll maxitem={100} lang={lang} dtype="research" />
              </div>
            </div>
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <Features gridItems={intro.blurbs} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer lang={lang} />
    </div>
  );
};

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  lang: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const ProductPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout>
      <ProductPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        lang={frontmatter.lang}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        contentComponent={HTMLContent}
        content={html}
      />
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        lang
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
            title
          }
        }
      }
    }
  }
`;
